import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight, Search } from "lucide-react";

const MAX_POKEMON_ID = 898; // As of Generation VIII

const typeColors = {
  normal: "rgba(168, 167, 122, 0.7)",
  fire: "rgba(238, 129, 48, 0.7)",
  water: "rgba(99, 144, 240, 0.7)",
  electric: "rgba(247, 208, 44, 0.7)",
  grass: "rgba(122, 199, 76, 0.7)",
  ice: "rgba(150, 217, 214, 0.7)",
  fighting: "rgba(194, 46, 40, 0.7)",
  poison: "rgba(163, 62, 161, 0.7)",
  ground: "rgba(226, 191, 101, 0.7)",
  flying: "rgba(169, 143, 243, 0.7)",
  psychic: "rgba(249, 85, 135, 0.7)",
  bug: "rgba(166, 185, 26, 0.7)",
  rock: "rgba(182, 161, 54, 0.7)",
  ghost: "rgba(115, 87, 151, 0.7)",
  dragon: "rgba(111, 53, 252, 0.7)",
  dark: "rgba(112, 87, 70, 0.7)",
  steel: "rgba(183, 183, 206, 0.7)",
  fairy: "rgba(214, 133, 173, 0.7)",
};

const Pokedex = () => {
  const [pokemonId, setPokemonId] = useState(1);
  const [pokemon, setPokemon] = useState(null);
  const [pokemonDescription, setPokemonDescription] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchPokemonData = async (identifier) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://pokeapi.co/api/v2/pokemon/${identifier
          .toString()
          .toLowerCase()}`
      );
      if (!response.ok) throw new Error("Pokemon not found");
      const data = await response.json();
      setPokemon(data);
      setPokemonId(data.id);

      const speciesResponse = await fetch(data.species.url);
      const speciesData = await speciesResponse.json();
      const englishFlavorText = speciesData.flavor_text_entries.find(
        (entry) => entry.language.name === "en"
      );
      setPokemonDescription(
        englishFlavorText
          ? englishFlavorText.flavor_text
          : "No description available."
      );

      setError("");
    } catch (err) {
      setPokemon(null);
      setPokemonDescription("");
      setError("Pokemon not found. Please try another name or ID.");
    }
    setLoading(false);
  };

  const goToPrevious = () => {
    const newId = pokemonId > 1 ? pokemonId - 1 : MAX_POKEMON_ID;
    setPokemonId(newId);
    fetchPokemonData(newId);
  };

  const goToNext = () => {
    const newId = pokemonId < MAX_POKEMON_ID ? pokemonId + 1 : 1;
    setPokemonId(newId);
    fetchPokemonData(newId);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      fetchPokemonData(searchTerm);
    }
  };

  const handleSliderChange = (event) => {
    const newId = parseInt(event.target.value);
    setPokemonId(newId);
    fetchPokemonData(newId);
  };

  useEffect(() => {
    fetchPokemonData(1);
  }, []);

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      fontFamily: "Arial, sans-serif",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      background: "linear-gradient(45deg, #ee1515, #f00000, #ff0000)",
      backgroundSize: "200% 200%",
      animation: "scrollingCheckerboard 10s linear infinite",
    },
    pokedex: {
      width: "100%",
      maxWidth: "350px",
      padding: "20px",
      backgroundColor: "rgba(255, 255, 255, 0.25)",
      borderRadius: "20px",
      boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
      backdropFilter: "blur(4px)",
      border: "1px solid rgba(255, 255, 255, 0.18)",
      transition: "all 0.3s ease",
      display: "flex",
      flexDirection: "column",
    },
    header: {
      marginBottom: "15px",
      display: "flex",
      alignItems: "center",
    },
    mainLight: {
      width: "40px",
      height: "40px",
      backgroundColor: "rgba(77, 182, 172, 0.7)",
      borderRadius: "50%",
      marginRight: "10px",
      boxShadow: "0 0 15px rgba(77, 182, 172, 0.8)",
      border: "2px solid rgba(255, 255, 255, 0.5)",
      transition: "all 0.3s ease",
    },
    mainLightGlow: {
      boxShadow: "0 0 30px rgba(77, 182, 172, 1)",
    },
    smallLights: {
      display: "flex",
      gap: "8px",
    },
    smallLight: {
      width: "15px",
      height: "15px",
      borderRadius: "50%",
      boxShadow: "inset 0 0 5px rgba(0, 0, 0, 0.3)",
      animation: "blinkLight 2s infinite",
    },
    screen: {
      marginBottom: "15px",
      backgroundColor: "rgba(152, 255, 152, 0.3)",
      padding: "15px",
      borderRadius: "10px",
      boxShadow: "inset 0 0 20px rgba(0, 0, 0, 0.1)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "300px",
      overflow: "hidden",
      backdropFilter: "blur(5px)",
    },
    pokemonImage: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      animation: "float 3s ease-in-out infinite",
    },
    noImage: {
      color: "rgba(0, 0, 0, 0.5)",
      fontSize: "16px",
    },
    searchContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "15px",
    },
    searchInput: {
      flex: 1,
      padding: "8px",
      marginRight: "10px",
      borderRadius: "20px",
      border: "none",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      color: "white",
      boxShadow: "inset 0 2px 5px rgba(0, 0, 0, 0.1)",
    },
    searchButton: {
      backgroundColor: "rgba(77, 182, 172, 0.7)",
      color: "white",
      padding: "8px",
      borderRadius: "50%",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    },
    slider: {
      width: "100%",
      marginBottom: "15px",
      WebkitAppearance: "none",
      background: "rgba(152, 255, 152, 0.3)",
      outline: "none",
      opacity: "0.7",
      transition: "opacity .2s",
      borderRadius: "10px",
      height: "10px",
    },
    sliderThumb: {
      WebkitAppearance: "none",
      appearance: "none",
      width: "20px",
      height: "20px",
      background: "rgba(77, 182, 172, 0.7)",
      cursor: "pointer",
      borderRadius: "50%",
      border: "2px solid rgba(255, 255, 255, 0.5)",
      boxShadow: "0 0 5px rgba(0,0,0,0.2)",
    },
    pokemonInfo: {
      textAlign: "left",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      borderRadius: "10px",
      padding: "15px",
      marginBottom: "15px",
      boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1)",
      overflow: "hidden",
      backdropFilter: "blur(5px)",
    },
    pokemonName: {
      fontSize: "24px",
      fontWeight: "600",
      marginBottom: "10px",
      textTransform: "capitalize",
      color: "white",
      textAlign: "center",
      textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
    },
    pokemonDetail: {
      marginBottom: "5px",
      fontSize: "14px",
      color: "rgba(255, 255, 255, 0.8)",
      animation: "typing 1s steps(30, end)",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    pokemonDescription: {
      marginTop: "10px",
      fontSize: "14px",
      color: "rgba(255, 255, 255, 0.8)",
      lineHeight: "1.4",
    },
    typeContainer: {
      display: "flex",
      gap: "5px",
      marginBottom: "5px",
    },
    typeIcon: {
      padding: "3px 8px",
      borderRadius: "15px",
      color: "white",
      fontWeight: "bold",
      fontSize: "12px",
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
      backdropFilter: "blur(5px)",
    },
    error: {
      color: "rgba(255, 255, 255, 0.8)",
      marginBottom: "10px",
      textAlign: "center",
      fontWeight: "bold",
      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.3)",
    },
    navButton: {
      backgroundColor: "rgba(77, 182, 172, 0.7)",
      color: "white",
      padding: "8px",
      borderRadius: "50%",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    },
  };

  return (
    <div style={styles.container}>
      <style>
        {`
          body, html {
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
          @keyframes gradientBG {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
          @keyframes float {
            0% { transform: translateY(0px); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0px); }
          }
          @keyframes blinkLight {
            0%, 100% { opacity: 0.3; }
            50% { opacity: 1; }
          }
          @keyframes typing {
            from { width: 0 }
            to { width: 100% }
          }
          @keyframes scrollingCheckerboard {
            0% { background-position: 0% 0%; }
            100% { background-position: 100% 100%; }
          }
          input[type=range]::-webkit-slider-thumb {
            ${Object.entries(styles.sliderThumb)
              .map(([key, value]) => `${key}: ${value};`)
              .join("\n")}
          }
          input[type=range]::-moz-range-thumb {
            ${Object.entries(styles.sliderThumb)
              .map(([key, value]) => `${key}: ${value};`)
              .join("\n")}
          }
          ::placeholder {
            color: rgba(255, 255, 255, 0.7);
          }
        `}
      </style>
      <div style={styles.pokedex}>
        <div style={styles.header}>
          <div
            style={{
              ...styles.mainLight,
              ...(loading ? styles.mainLightGlow : {}),
            }}
          ></div>
          <div style={styles.smallLights}>
            <div
              style={{
                ...styles.smallLight,
                backgroundColor: "rgba(255, 82, 82, 0.7)",
                animationDelay: "0s",
              }}
            ></div>
            <div
              style={{
                ...styles.smallLight,
                backgroundColor: "rgba(255, 235, 59, 0.7)",
                animationDelay: "0.6s",
              }}
            ></div>
            <div
              style={{
                ...styles.smallLight,
                backgroundColor: "rgba(105, 240, 174, 0.7)",
                animationDelay: "1.2s",
              }}
            ></div>
          </div>
        </div>

        <div style={styles.screen}>
          {pokemon ? (
            <img
              src={
                pokemon.sprites.other["official-artwork"].front_default ||
                pokemon.sprites.front_default
              }
              alt={pokemon.name}
              style={styles.pokemonImage}
            />
          ) : (
            <div style={styles.noImage}>
              {loading ? "Loading..." : "No Pokemon loaded"}
            </div>
          )}
        </div>

        <div style={styles.searchContainer}>
          <button onClick={goToPrevious} style={styles.navButton}>
            <ChevronLeft size={24} />
          </button>
          <form
            onSubmit={handleSearch}
            style={{ display: "flex", flex: 1, margin: "0 10px" }}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter Pokemon name or ID"
              style={styles.searchInput}
            />
            <button type="submit" style={styles.searchButton}>
              <Search size={20} />
            </button>
          </form>
          <button onClick={goToNext} style={styles.navButton}>
            <ChevronRight size={24} />
          </button>
        </div>

        <input
          type="range"
          min="1"
          max={MAX_POKEMON_ID}
          value={pokemonId}
          onChange={handleSliderChange}
          style={styles.slider}
        />

        {error && <p style={styles.error}>{error}</p>}

        {pokemon && (
          <div style={styles.pokemonInfo}>
            <h2 style={styles.pokemonName}>
              {pokemon.name} (#{pokemon.id})
            </h2>
            <div style={styles.typeContainer}>
              {pokemon.types.map((type) => (
                <span
                  key={type.type.name}
                  style={{
                    ...styles.typeIcon,
                    backgroundColor: typeColors[type.type.name],
                  }}
                >
                  {type.type.name.toUpperCase()}
                </span>
              ))}
            </div>
            <p style={styles.pokemonDetail}>Height: {pokemon.height / 10} m</p>
            <p style={styles.pokemonDetail}>Weight: {pokemon.weight / 10} kg</p>
            <p style={styles.pokemonDetail}>
              Abilities:{" "}
              {pokemon.abilities
                .map((ability) => ability.ability.name)
                .join(", ")}
            </p>
            <p style={styles.pokemonDescription}>{pokemonDescription}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pokedex;
